import React from 'react'
import { get } from 'lodash'
import Layout from '../components/layout/Layout'
import Img from 'gatsby-image'
import SEO from '../components/seo/SEO'
import { graphql } from 'gatsby'

class AboutUs extends React.Component {
  render() {
    const pageTitle = 'About Us'
    const pageDescription = 'About us: Wendy Rhodes, founder and chief editor of Weighted Blanket Guides, and her team.'

    const siteConfig = get(this, 'props.data.site.siteMetadata')
    const seoConfig = {
      ...siteConfig,
      url: get(this, 'props.location.href', `https://www.weightedblanketguides.com/about-us/`),
      title: `${pageTitle} - ${get(this, 'props.data.site.siteMetadata.name')}`,
      description: pageDescription
    }

    const content = `
      <h2>The Team</h2>
      <h3>Rachel Green</h3>
      <p>Rachel Green is a regular contributor at WeightedBlanketGuides.com. She passionately promotes the numerous health benefits that weighted blankets and deep touch pressure offer individuals. When she is not writing, she enjoys spending time with her husband and four children in Chattanooga, Tennessee.</p>
      <h3>Wendy Rhodes</h3>
      <img alt="" width="100" src="/wendy-rhodes.jpg"/>
      <p>Wendy Rhodes, founder and chief editor of Weighted Blanket Guides, was initially introduced to the idea that weighted blankets can be a powerful therapeutic tool by a family friend. As a single mother with a child on the spectrum, she experienced firsthand the positive impact these blankets had on her son's wellbeing. And yet despite this knowledge and experience, Wendy found there wasn't enough comprehensive research available about them — which is why she founded WeightedBlanketGuides.com to fill this gap in information.</p>
      <p>Fueled by her motivation to make a difference, Wendy started researching and writing on Weighted Blanket Guides to make it an informative website providing research-based knowledge regarding the potential advantages of weighted blankets for a range of problems, discomforts, and conditions. Through partnering with an enthusiastic team of writers, Wendy puts high emphasis on that all published information is accurate, comprehensive, thoroughly researched and backed up through reliable sources, and correctly cited.</p>
      <p>
        You can find her on these social media platforms:<br/>
        <a href="https://www.linkedin.com/in/wendy-rhodes/" target="_blank"><img alt="" width="70" src="/linkedin-logo.png"/></a>
      </p>
     `

    return (
      <Layout location={this.props.location}>
        <SEO postData={{}} postImage={''} isBlogPost={false} config={seoConfig}/>
        <div className="page__container">
          <h1>{pageTitle}</h1>
          <Img fluid={{...this.props.data.file.childImageSharp.fluid}} alt={pageTitle}/>
          <br/>
          <div dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>
      </Layout>
    )
  }
}

export default AboutUs

export const AboutUsQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        name
        description
        siteUrl
      }
    }
    file(relativePath: {eq: "about-us-weightedblanketguides.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 740) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
